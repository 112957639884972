.card-product{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 28px 28px 0 0;
    padding-bottom: 30px;
}

.cardRestoran-product-wrapper{
    width: 80%;
    margin: 0 auto;
}

.close-cardRestoran-product{
    width: 40px;
    float: right;
    margin: 10px;
}

.cardRestoran-product-info{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardRestoran-product-info>h3{
    align-self: flex-start;
    margin-bottom: 5px;
}

.cardRestoran-product-info>span{
    padding: 0 20px;
}

.line-card{
    width: 100%;
    height: 3px;
    background-color: #9f9f9f;
    margin: 15px 0;
}

.cardRestoran-product-info>div{
    font-size: 14px;
    margin-top: 10px;
}

.contact-info-rostoran{
    margin-bottom: 5px;
}