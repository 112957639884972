.icon-item-cafe{
    width: 13px;
}

.logo-item-cafe{
    width: 100%;
    height: 200px;
}

.card-wrapper{
    text-decoration: none;
}

.name-cafe{
    font-weight: 700;
    color: rgba(138, 138, 138, 0.8);
    font-size: 18px;
}