.root {
  margin: 0px 23px 24px 23px;
  padding-bottom: 81px;
}

.title_cart {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */
  margin-left: 20px;
  color: #404246;
}

.cart_header_btns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 20px;
}

.header_btn {
  margin: 0 auto;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid #b2b2b2;
}
.header_btn_active {
  margin: 0 auto;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ff7a00;
}
.items_wrapper {
  margin-top: 25px;
}

.item {
  width: 100%;
  padding: 12px 14px 8px 16px;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 25px;
  display: flex;
}

.item_img {
  width: 112px;
  display: flex;
  align-items: center;
}

.item_img img {
  width: 100%;
  border-radius: 5px;
}

.item_content {
  margin-left: 18px;
}

.item_title {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 16px;
  color: #404246;
}

.item_detail {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
  display: flex;
}

.item_detail div {
  font-weight: bold;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 5px;
}

.item_content button {
  border: 1px solid #ff7a00;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  width: 109px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  color: #ff7a00;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-top: 7px;
  background: none;
}

.cart_empty {
  width: 100%;
}

.cart_empty h2,
.cart_empty p {
  text-align: center;
}

.cart_empty p {
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cart_empty img {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.btnOformit {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  color: #404246;
}

.form_delivery {
  margin-top: 21px;
}

.input_label {
  display: flex;
  flex-direction: column;
}

.input_label label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.input_label input,
.input_label textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid #404246;
  border-radius: 5px;
  background: none;
  opacity: 0.7;
  margin-bottom: 20px;
}

.input_label label:focus,
.input_label input :focus {
  opacity: 1;
}

.btn_back_next {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.btn_back_next div,
.form_delivery button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;

  width: 176px;
  height: 40px;

  border: 1px solid #ff7a00;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  font-family: var(--GothamProRegular);
  color: #404246;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.form_delivery button {
  width: 100%;
  background: #ff7a00;
  color: #404246;
  font-weight: bold;
  margin-top: 20px;
}

.radio {
  display: flex;
  align-items: center;
  font-family: var(--GothamProRegular);
  margin-top: 21px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  color: #404246;
}
.radio label {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.radio input {
  width: 19px;
  height: 18px;
  margin-left: 24px;
}

.errorForm {
  color: red;
  margin: 0;
  height: 17px;
  font-size: 12px;
}
