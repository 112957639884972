.root {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 2;
  background: #fff;
  border: 1px solid #0000004a;
  border-radius: 5px;
  width: 40px;
  text-align: center;
}

.root:active {
  opacity: 0.8;
}
