.modal-menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(88, 88, 88, 0.6);
    z-index: 999; 
}

.modalM{
    position: fixed;
    top: 0;
    right: 0;
    width: 65%;
    background-color: #fff;
}

.modal-wrapper{
    display: flex;
    justify-content: space-between;
}

.close-menu{
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 10px;
    background-repeat: no-repeat;
}

.modal-contentM{
    color: #9d9f9e;
    margin: 15px 0 0 20px;
}

.menu-item{
    margin-top: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #9d9f9e;
}

.menu-item-link{
    text-decoration: none;
}

.menu-item>img{
    height: 30px;
}

.menu-item>p{
    font-size: 22px;
    margin-left: 15px;
}