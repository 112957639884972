.item-cafe{
    margin-top: 20px;
}

.item-cafe-wrapper{
    width: 100%;
    margin: 0 auto;
}

.choise-type{
    display: flex;
    align-items: center;
}

.choise-type>p{
    text-align: center;
    width: calc(100%/3);
    padding-bottom: 13px;
    border-bottom: 1px solid #c1c1c1;
    cursor: pointer;
}

.choise-type>p:hover{
    border-bottom: 4px solid #d78231;
    padding-bottom: 10px;
}



/* item-prod */
.item-prod{
    width: 100%;
    background-color: #fff;
    display: flex;
    padding: 14px;
    border-radius: 18px;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 15px;
    cursor: pointer;
    border: 1px solid #cfcfcf;
    box-shadow: 5px 5px 15px #7d7d7d;
}

.item-prod>img{
    width: 100px;
    height: 100px;
}

.item-prod-text>h1{
    font-size: 25px;
}

.item-prod-text>p{
    margin-top: 15px;
    font-size: 18px;
}

.item-prod-star-reit{
    margin-top: 15px;
    margin-right: 5px;
    width: 15px;
    height: 15px;
}



.empty-text-menu{
    text-align: center;
    font-size: 18px;
    padding: 120px 0;
}


.showGoBasket{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: rgb(77, 89, 255);
    animation-duration: 1s;
    border-radius: 18px 18px 0 0;
    animation-name: inBasket;
    opacity: .8;
}

@keyframes inBasket {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100%{
        opacity: .8;
        transform: translateY(0);
    }
}

.goBasket{
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
}

.goBasket>div{
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}


