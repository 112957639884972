.modalAlert{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    min-height: 100vh;
}

.alert-wrapper{
    background: #fff;
    padding: 18px;
    border-radius: 18px;
    text-align: center;
}

.alert-wrapper>p{
    font-size: 17px;
    padding: 15px 0 38px 0;
}

.alert-wrapper>a{
    text-decoration: none;
}

.alert-wrapper>a:nth-child(3)>button{
    margin-bottom: 20px;
}

.confirm-del-last-item{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.confirm-del-last-item>button{
    width: 35%;
}