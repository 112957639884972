.input {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input > input {
  height: 100%;
  width: 85%;
  outline: none;
  border: 3px solid #fff;
  border-radius: 18px;
  background-color: #e5e5e5;
  padding: 2px 10px 2px 30px;
  width: 100%;
}

.input > input[type='text'] {
  background-image: url('../../asseets/search.png');
}

.input > input[type='text'] {
  content: '';
  /* position: absolute; */
  top: 0;
  left: 0;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 10px;
}

.btn_sort {
  height: 100%;
  width: 35px;
  outline: none;
  border: none;
  border-radius: 50%;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #9f9f9f;
  margin: 15px 0;
}
