.card-product{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 28px 28px 0 0;
    padding-bottom: 30px;
    animation-duration: .8s;
    animation-name: showCard;
}

@keyframes showCard {
    0% {
        opacity: 0;
        transform: translateY(250px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

.card-product-wrapper{
    width: 80%;
    margin: 0 auto;
}

.close-card-product{
    width: 40px;
    float: right;
    margin: 10px;
}

.card-product-info{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.card-product-info>img{
    width: 230px;
}

.card-product-info>h3{
    align-self: flex-start;
    margin: 10px 0;
}

.card-product-info>span{
    padding: 0 20px;
}

.line-card{
    width: 100%;
    height: 3px;
    background-color: #9f9f9f;
    margin: 15px 0;
}

.item-num.card-num{
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}