.formAddPartner{
    width: 100%;
}

.formAddPartner-wrapper{
    width: 90%;
    margin: 0 auto;
    padding: 20px 0 40px 0;
}

.form-addPartner{
    padding: 20px 0;
}

.form-text-addPartner:not(:last-child){
    margin-bottom: 18px;
}

.form-text-addPartner>input[type='text']{
    width: 100%;
    font-size: 18px;
    padding: 8px;
    outline: none;
    border-radius: 8px;
    border: 1px solid rgb(197, 197, 197);
}

.form-text-addPartner>p{
    font-size: 17px;
    padding-bottom: 8px;
    font-weight: 500;
}

.form-text-addPartner>select{
    width: 100%;
    font-size: 18px;
    padding: 8px;
    outline: none;
    border-radius: 8px;
    border: 1px solid rgb(197, 197, 197);
}

.form-text-addPartner>div{
    margin-top: 8px;
}

.form-text-addPartner>div>span{
    margin-left: 18px;
}

.form-text-addPartner>input[type='checkbox']{
    width: 15px;
    height: 15px;
}

select{
    color: rgb(119, 119, 119);
}

.form-text-addPartner>input[type='file']{
    padding: 5px;
}


.load-file {
    width: 100%;
    position: relative;
    margin: 15px 0;
    text-align: center;
}

.load-file>input{
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.load-file>label{
    display: flex;
    align-items: center;
    width: 100%;
}

.load-file-button{
    background-color: rgb(43, 40, 255);
    color: #fff;
    font-size: 16px;
    padding: 8px 28px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 18px;
}