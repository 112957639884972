.root {
  /* margin-bottom: 24px; */
}
.title_slider_container {
  margin: 0 27px;
  margin-bottom: 16px;
  color: #25272e;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
}
.wrapper_slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 104px;
}

.slide {
  /* width: 360px; */
  /* width: calc(26% / 12 * 6 - 8px); */

  /* height: 240px; */
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  margin: 0px 27px;
  border-radius: 5px;
  position: relative;
}

.slide_previous {
  /* width: 360px;
  height: 240px; */
}

.slide_previous img {
  width: 100%;
}

.side_link_shop span {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #404246;
}
.side_link_shop {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  left: 263px;
  top: 202px;
}
.side_link_shop:active {
  opacity: 0.5;
}

.slide_content {
  position: absolute;
  top: 24px;
  left: 15px;
  width: 150px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
  color: #ff7a00;
}

@media (max-width: 400px) {
  .slide {
    margin: 0 14px;
  }
}

@media (max-width: 375px) {
  .slide_previous {
    width: 351px;
    height: 200px;
  }
  .slide {
    width: 351px;
    height: 234px;
    margin: 0 12px;
  }
  .side_link_shop {
    left: 247px;
    top: 194px;
  }
}

@media (max-width: 353px) {
  .slide_previous {
    width: 296px;
    height: 200px;
  }
  .slide {
    width: 296px;
    height: 197px;
    margin: 0 12px;
  }
  .side_link_shop {
    left: 201px;
    top: 158px;
  }
}
