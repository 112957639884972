/* Main Page */
.main,
.backet,
.cafe,
.gifts,
.history,
.cabinet,
.error,
.records {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
}

.main-wrapper,
.backet-wrapper,
.cafe-wrapper,
.gifts-wrapper,
.history-wrapper,
.cabinet-wrapper,
.error-wrapper,
.records-wrapper {
  margin: 0 auto;
  width: 90%;
}

.title {
  font-size: 24px;
  color: #a0a0a0;
  margin: 20px 0;
}

.cafe-item {
  margin-top: 20px;
}

.name-group {
  font-size: 24px;
  color: #a0a0a0;
  margin-right: 15px;
}

.main-title-seacrh-input {
  display: flex;
  align-items: center;
}

.cardM {
  height: 300px;
  display: flex;
  align-items: center;
  border: 1px solid #cfcfcf;
  border-radius: 18px;
  box-shadow: 5px 5px 10px #7d7d7d;
  background-color: #fff;
}

.card-wrapper {
  height: 95%;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card > a {
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  color: #000;
}

.info,
.info-left,
.info-left > div,
.info-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info > p,
.info-left > p,
.info-left > div,
.info-right {
  background-color: #e58400;
  color: #fff;
  padding: 3px 5px;
  border-radius: 10px;
}

.info-right > p {
  margin-left: 8px;
  margin-bottom: 0;
}

.info-left > div > p,
.info-left > div {
  margin-left: 12px;
  margin-bottom: 0;
}

.cafe-category {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.cafe-category-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  float: right;
}

/* Backet Shopping */

.shop,
.gifts,
.history,
.cabinet,
.records {
  margin: 15px 0;
}

.shop-text,
.gifts-text,
.history-text,
.cabinet-text,
.records-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cabinet-text > h4 {
  font-size: 24px;
  color: #5c5c5c;
}

.shop-text > h3,
.info-buy > h3,
.gifts-text > h3,
.history-text > h3,
.cabinet-text > h3,
.records-text > h3,
.address > h3,
.info-buy-price > h3 {
  color: #5c5c5c;
  font-size: 22px;
  font-weight: 700;
}

.info-buy-price {
  margin-top: 15px;
}

.shop-text > img {
  height: 10px;
  width: 20px;
  cursor: pointer;
}

.clear-basket {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.clear-basket > span {
  color: #5c5c5c;
  font-size: 17px;
}

.item-name,
.item-num,
.item {
  display: flex;
  align-items: center;
}

.item {
  justify-content: space-between;
  margin-bottom: 20px;
}

.item-num {
  font-size: 20px;
  font-weight: 600;
}

.item-name > p {
  color: #e58400;
  margin-left: 10px;
  font-size: 18px;
}

.item-name > img {
  width: 50px;
  max-height: 50px;
}

.item-num > p {
  margin: 0 10px;
}

.address {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
}

.address > div {
  display: flex;
  align-items: center;
}

.address > div > div {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.address > div > img {
  height: 20px;
  margin-right: 10px;
}

.address > div > span {
  color: #5c5c5c;
  font-size: 22px;
}

.choicePayment {
  font-size: 18px;
  margin-left: 30px;
  color: tomato;
}

.address > img {
  height: 10px;
  width: 20px;
  cursor: pointer;
  z-index: 999;
}

.info-buy:nth-child(5),
.info-buy:nth-child(6) {
  margin-top: 20px;
}

.total > div {
  color: #5c5c5c;
  font-size: 22px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.comment {
  width: 100%;
  font-size: 18px;
  margin: 15px 0 0 0;
  padding: 8px 0;
  outline: none;
  border: none;
  border-radius: 8px;
}

.comment[type='text'] {
  padding-left: 15px;
  padding-right: 15px;
}

.backet-empty {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backet-empty > img {
  width: 60%;
  margin-bottom: 50px;
}

.backet-empty > p {
  font-size: 24px;
}

.none {
  display: none;
  /* height: 0;
    transform: translateY(-50px);
    opacity: 0;
    transition: 0.3; */
}

.choice-payment {
  background-color: #c9c9c9;
  margin: 5px 0;
  border-radius: 18px;
  padding: 8px 18px;
  color: #5c5c5c;
  margin-top: 15px;
}

.choice-payment:hover {
  background-color: #a0a0a0;
  color: #fff;
  box-shadow: -1px 4px 12px #474747;
}

.cabinet-form-item-shoop {
  outline: none;
  border: none;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.cabinet-form-item-shoop > span {
  font-size: 18px;
  color: #777777;
}

.cabinet-form-item-shoop > input {
  outline: none;
  border: none;
  background-color: #e5e5e5;
  border-bottom: 1px solid #b4b4b4;
  font-size: 18px;
  padding: 6px 5px 2px 10px;
}

.cabinet-form-item-shoop:nth-child(6) {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  border: none;
}

.cabinet-form-item-shoop:nth-child(6) > input {
  margin-left: 20px;
  height: 20px;
  width: 20px;
}

.profile-add-adress-form {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

.cabinet-form-item-shoop:first-child {
  width: 60%;
}

.cabinet-form-item-shoop {
  width: 30%;
}

.adressUserFromDelivery {
  padding: 8px 0;
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.adressUserFromDelivery-item > div {
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100px;
  padding: 4px 8px;
  background-color: #c9c9c9;
  border-radius: 18px;
}

.adressUserFromDelivery-item > div > p {
  font-size: 12px;
  cursor: pointer;
}

.alert-input[type='text'] {
  padding-left: 15px;
  padding-right: 15px;
}
.alert-input {
  width: 100%;
  font-size: 18px;
  margin: 15px 0 0 0;
  padding: 8px 0;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  color: #979797;
}

.alert-form-item {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

/* Cafe Page */

.breadcrumb {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
}

.breadcrumb > a {
  font-size: 18px;
  text-decoration: none;
  color: #848484;
}

.breadcrumb > p {
  color: #5a5a5a;
  font-weight: 600;
  font-size: 18px;
}

.breadcrumb > span {
  margin: 0 10px;
}

.cafe-logo-main {
  margin: 10px 0;
}

.cafe-log-about {
  position: relative;
}

.cafe-log-about > img {
  width: 100%;
  height: 178px;
}

.delivery-clock {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  background-color: #f67c01;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.delivery-clock > img {
  width: 14px;
  margin-right: 5px;
}

.delivery-clock > span {
  font-size: 14px;
  color: #fff;
}

.cafe-logo-main-info {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.cafe-logo-main-info > div:not(:first-child, :last-child) {
  display: flex;
  flex-direction: column;
  /* width: calc(100% / 3); */
  /* padding: 0 10px; */
}

.delivery-info {
  font-size: 12px;
  background: #c0c0c0;
  color: #fff;
  text-align: center;
  height: 40%;
  padding: 5px;
  border-radius: 12px 12px 0 0;
}

.cafe-logo-main-info > div > span {
  font-size: 16px;
  background: #fff;
  color: #c0c0c0;
  text-align: center;
  display: block;
  height: 60%;
  border-radius: 0 0 12px 12px;
  border: 2px solid #c0c0c0;
}

.delivery-info-time > span {
  padding: 2px 0;
  font-size: 16px;
  background: #fff;
  color: #f67c01;
  width: 100%;
  border: 2px solid #f67c01;
  border-radius: 0 0 14px 14px;
  text-align: center;
}

.opis-cafe {
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: #f67c01;
  padding: 12px;
  border-radius: 18px;
}

/* Gifts Page */
.item-gift {
  width: 100%;
  box-shadow: 9px 9px 18px #686868;
  border-radius: 28px;
  margin: 18px 0;
}

.item-gift > img {
  width: 100%;
  height: 180px;
  border-radius: 28px 28px 0 0;
}

.item-gift-text {
  width: 100%;
  background-color: #fff;
  padding: 15px 25px;
  border-radius: 0 0 28px 28px;
}

.item-gift-text > h3 {
  color: #e58400;
  font-weight: 700;
  margin-bottom: 10px;
}

.item-gift-text > p {
  color: #868686;
  font-size: 16px;
}

/* History Page */
.history-items {
  width: 100%;
}

.item-history {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 9px 9px 10px #686868;
  border-radius: 18px;
  padding: 8px 22px;
}

.marginBottom {
  margin-bottom: 18px;
}

.item-history > p {
  margin: 5px 0;
  font-size: 12px;
}

.item-history-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-history-text > span {
  background-color: #e58400;
  color: #fff;
  padding: 4px 8px;
  border-radius: 6px;
}

.item-history-price-arrow {
  display: flex;
  align-items: center;
}

.item-history-price-arrow > img {
  width: 18px;
  margin-left: 18px;
}

/* Cabinet Page */
.cabinet-form-reg {
  width: 100%;
}

.form-inner {
  width: 100%;
  background-color: #fefefe;
  padding: 15px 20px;
  margin-bottom: 30px;
}

.cabinet-form-item {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.cabinet-form-item:nth-child(6) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cabinet-form-item:nth-child(6) > input {
  margin-left: 20px;
  height: 30px;
  width: 30px;
}

.cabinet-form-item-switch {
  padding: 10px 0;
}

.checkToken {
  margin-bottom: 15px;
}

.cabinet-form-item > span,
.checkToken > span {
  font-weight: 700;
}

.cabinet-form-item-switch > input {
  margin-left: 20px;
  height: 20px;
  width: 20px;
}

.cabinet-form-item > input,
.checkToken > input {
  outline: none;
  border: none;
  border-bottom: 1px solid #b4b4b4;
  font-size: 22px;
}

.cabinet-form-item-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cabinet-form {
  width: 90%;
  margin: 0 auto;
  padding: 5px 0;
}

/* Error Page */
.error {
  height: 100vh;
  background-color: #fefefe;
}

.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 30px;
}

.error-modal {
  width: 100%;
  border-radius: 8px;
  padding: 10px 0;
  background-color: #f67c01;
  display: flex;
}

.big-close-er {
  width: 40px;
  height: 40px;
  margin: 0 15px;
}

.small-close-er {
  width: 25px;
  height: 25px;
  margin: 0 10px;
}

.modal-info-er > p:nth-child(1) {
  font-weight: 700;
  color: #fff;
}

.modal-info-er > p:nth-child(2) {
  color: #fff;
  font-size: 14px;
}

.logo-robo {
  width: 50%;
  margin: 80px 0 50px 0;
}

.error-text-h3 {
  text-align: center;
}

.error-text-p {
  margin: 20px 0;
  text-align: center;
  width: 80%;
  color: #8b8b8b;
}

/* Records Page */
.block-bonus {
  width: 100%;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 4px 7px 13px #5f5f5f;
  text-align: center;
  padding: 15px 0;
}

.block-bonus > p {
  color: #868686;
  margin-bottom: 15px;
}

.block-bonus > h3 {
  font-size: 24px;
  color: #3f3f3f;
}

.recodrs-content > h2 {
  text-align: center;
  color: #fb7b00;
  padding: 60px 0 20px 0;
}

.items-trophy {
  width: 100%;
}

.trophy {
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
  border: 3px solid #fb7b00;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trophy > img {
  width: 60%;
  padding: 20px 0;
}

.line-trophy {
  width: 80%;
  height: 2px;
  background-color: #c7c7c7;
  margin: 0 auto;
}

.trophy > p {
  margin: 20px 0;
  text-align: center;
  color: #878787;
}

.loader-main {
  margin-bottom: 20px;
  transform: scale(0.9);
}

.authStatusTrue {
  margin-top: 150px;
}

.authStatusTrue > h3 {
  margin-bottom: 50px;
  text-align: center;
}

.link {
  text-decoration: none;
}

/* Profile Page */
.profile-item {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.profile-item:first-child {
  margin-top: 28px;
}

.profile-item > p {
  font-size: 22px;
  font-weight: 700;
  color: #7a7a7a;
}

.profile-item > img {
  margin-right: 18px;
  width: 18px;
}

.profile-add-adress {
  font-weight: 700;
  font-size: 28px;
  color: #fff;
  background: tomato;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-payment-form-map {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-payment-form > input {
  outline: none;
  font-size: 18px;
  width: 45%;
  padding: 6px 5px 2px 10px;
  margin-top: 8px;
  background: none;
  border: none;
  border-bottom: 1px solid #b4b4b4;
}
