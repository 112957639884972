.header{
    background-color: #f67c01;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-wrapper{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.header-icons{
    display: flex;
    align-items: center;
}

.menu-logo{
    padding: 18px 0 18px 18px;
    cursor: pointer;
}

.basket-logo{
    position: relative;
    display: flex;
    cursor: pointer;
}

.menu-icon{
    height: 14px;
}

.basket-icon{
    height: 34px;
    filter: brightness(100);
    /* position: absolute; */
    margin: 15px 24px 0 24px;
}

.header-log-prevBtn{
    display: flex;
    align-items: center;
}

.header-log-prevBtn>a{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.header-prevBtn{
    filter: brightness(100);
    transform: rotate(90deg);
    height: 10px;
}

.header-prevBtn{
    margin-right: 15px;
}

.basket-logo>span{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: red;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    bottom: px;
    top: 5px;
}