.customBtn {
  width: 100%;
  height: 40px;

  background: #ff7a00;
  border: 1px solid #ff7a00;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}

.customBtn:active {
  opacity: 0.5;
}
.off_btn {
  width: 100%;
  height: 40px;

  background: #ff7a00;
  border: 1px solid #ff7a00;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  opacity: 0.5;
}
