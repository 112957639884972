.cart_empty {
  width: 100%;
  margin-top: 30%;
}

.cart_empty h2,
.cart_empty p {
  text-align: center;
}

.cart_empty p {
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cart_empty img {
  width: 80%;
  margin: 0 auto;
  display: block;
}
