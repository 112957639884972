.wrapperModalorderDone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000073;
  z-index: 10;
}

.modalOrderDone {
  position: relative;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 165%);
  -moz-transform: translate(-50%, 165%);
  -ms-transform: translate(-50%, 165%);
  -o-transform: translate(-50%, 165%);
  transform: translate(-50%, 165%);
  background: #ffffff;
  width: 296px;
  height: 176px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 29px;
}

.titleModal {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  display: flex;
  align-items: flex-end;

  color: #000000;
  margin-bottom: 36px;
}

.modal button {
  width: 136px;
  height: 40px;

  background: #ff7a00;
  border: 1px solid #ff7a00;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
