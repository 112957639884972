.reg_wrapper {
  text-align: center;
}

.reg_title {
  background: linear-gradient(72deg, #F4A582 20%, #FF608D 51.56%, #fa00ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 126px;
  font-size: 20px;
  margin-bottom: -20px;
}

.registration_form {
  margin-top: 32px;
  text-align: center;
  padding: 10px;
  color: #5c5c66;
  font-size: 90%;
}

.inputgroup {
  margin-bottom: 10px;
  text-align: center;
}

.reg_placeholder_phone {
  border: none; /* Убираем рамку */
  outline: none; /* Убираем свечение в Chrome и Safari */
  -webkit-appearance: none; /* Убираем рамку в Chrome и Safari */
  width: 170px; /* Ширина поля */
  height: 38px;
  border-radius: 5px 0 0 5px;
  padding-left: 24px;
  
}

.reg_placeholder {
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  width: 208px;
  height: 40px;
  margin-bottom: 16px;
  padding-left: 24px;
  color: #282c34;
}

.reg_btn {
  border: 1px;
  border-image-source: linear-gradient(266.64deg, #f4a582 99.93%, #d432d8 -0.07%, #ff608d 51.49%);
  border-radius: 5px;
  width: 205px;
  height: 35px;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 200;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
  color: #f3f3f3;
  display: inline;
}

.reg_abs {
  border: 1px;
  border-image-source: linear-gradient(266.64deg, #f4a582 99.93%, #d432d8 -0.07%, #ff608d 51.49%);
  border-radius: 5px;
  width: 159px;
  height: 40px;
  font-size: 20px;
  font-weight: 300;
  border: none;
  background-color: var(--mainColor);
  color: #ff608d;
  display: inline;
}
.reg_btn:active {
  opacity: 0.6;
}
.inputGroup a {
  color: #ff608d;
  font-size: 20px;
  margin-top: 20px;
  display: block;
  text-decoration: none;
}
.input {
  color: #a8a8a8;
}
.error {
  color: red;
  margin: 5px;
}
.p1 {
  text-align: center;
  margin-top: -20px;
  text-decoration-line: none;
  border-bottom: 1px solid;
  padding-bottom: 3px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.p2 {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 3px;
  width: 95px;
  margin-bottom: 5px;
  display: inline-block;
}
.p3 {
  text-align: center;
  padding-bottom: 3px;
  width: 130px;
  margin-bottom: 5px;
  display: inline-block;
}
.footer {
  text-align: center;
  margin-top: 55px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0px;
  text-align: center;
  width: 34px;
}

.reg_reset_btn {
  width: 90px; /* Ширина кнопки */
  height: 38px; /* Высота кнопки */
  border: none; /* Убираем рамку */
  background: url(3.png) no-repeat 1%; /* Параметры фона */
  background-size: 38px 38px;
  display: inline-block;
  border-radius: 0 5px 5px 0;
}

.PhoneInputReset {
  min-height: 20px; /* Минимальная высота */
  display: flex;
  margin: 0px auto auto auto;
  /* border: 0.5px solid #a8a8a8; */
  border-radius: 5px;
  width: 208px;
  height: 40px;
  margin-bottom: 16px;
  padding-left: 0px;
  color: #282c34;

}
input[type=text] {
  /* width: 208px; */
  padding: 12px 20px;
  /* margin: 8px 0; */
  box-sizing: border-box;
  border: 0.5px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  background: #f3f3f3;
}

input[type=text]:focus {
  border: 0.5px solid rgb(129, 127, 127);
}

select {
  /* width: 208px; */
  /* padding: 12px 20px; */
  /* margin: 8px 0; */
  box-sizing: border-box;
  border: 0.5px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  background: #f3f3f3;
}
